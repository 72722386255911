import { default as Breadcrumbs } from '@jetshop/ui/Breadcrumbs';
import React, { useState } from 'react';
import { theme } from '../Theme';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import Image from '@jetshop/ui/Image/Image';
import { Above } from '@jetshop/ui/Breakpoints';
import Timeline from '../Timeline/Timeline';
import t from '@jetshop/intl';
import { Shortcodes } from '@jetshop/flight-shortcodes';
import VideoHero from '../StartPage/Content/VideoHero';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { allComponents } from '../StartPage/ContentRendererComponents/allcomponents';


export const CategoryHeaderWrapper = styled(MaxWidth)`
  display: block;
  text-align: center;
  ${theme.below.xl} {
    max-width: 100%;
    width: 100%;
    padding: 0px;
  }
  max-width: 92rem;
  ${theme.below.md} {
    margin-bottom: 0.75rem;
  }

  &.vimeo {
    max-width: 100%;
    width: 100%;
    padding: 0px;

    > div > p > *:not(.videoHeroWrapper) {
      padding: 0rem 2rem;
      display: block;
    }
  }
`;

const CategoryImageWrapper = styled('div')`
  height: 320px;
  width: 100%;
`;

export const NoImageWrapper = styled('div')`
  margin-top: 0.5rem;
  padding: 1rem;
  ${theme.below.md} {
    margin-top: 0;
  }
`;

export const CategoryName = styled('h1')`
  font-weight: 600;
  line-height: normal;
  font-size: 26px;
  margin-bottom: 10px;
  color: ${theme.colors.primary};
  &.with-content{
    margin-bottom: 2rem;
  }
  .contentComponent + & {
    margin-top: 4rem;
  }
`;

export const CategoryContent = styled('div')`
  max-width: 750px;
  line-height: 1.6;
  font-size: 14px;
  max-height: 50px;
  display: block;
  overflow: hidden;
  position: relative;
  padding-bottom: 20px;
  transition: all, 0.5s ease;
  color: ${theme.colors.primary};
  margin: 0 auto;
  width: 100%;

  ${theme.above.md} {
    padding-bottom: 0;
    max-height: 90px;
  }

  div.videoHeroWrapper {
    width: 100vw;
    transform: translatex(-50%);
    position: relative;
    left: 50%;
  }

  a {
    color: ${theme.colors.accent};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &.active {
    max-height: 1000px;
    &:after {
      display: none;
    }
  }
  a {
    color: ${theme.colors.accent};
  }

  a.CatButton {
    background: ${theme.colors.accent};
    color: ${theme.colors.white};
    border: 2px solid ${theme.colors.accent};
    display: inline-block;
    font-size: 14px;
    margin-top: 0.5rem;
    min-height: calc(22px + 0.5rem);
    padding: 0.25rem 1rem;
    text-decoration: none;
    transition: all ease 0.3s;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    &:hover {
      background: transparent;
      color: ${theme.colors.accent};
    }
  }
`;

export const SizeChartContent = styled('div')`
  max-width: 750px;
  width: 100%;
  line-height: 1.6;
  font-size: 14px;
  position: relative;
  padding-bottom: 3rem;
  color: ${theme.colors.primary};
  margin: 0 auto;
  ${theme.below.sm} {
    font-size: 12px;
    padding-bottom: 1rem;
  }

  h3 {
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  table {
    width: 500px;
    margin: auto;
    margin-top: 0.5rem;
    ${theme.below.lg} {
      width: 60vw;
    }
    ${theme.below.sm} {
      width: 85vw;
    }
    ${theme.below.xs} {
      width: 100%;
    }
  }
  tr {
    background: #f7f7f7;
    &:nth-child(even) {
      background: white;
      &:last-child {
        border-bottom: 5px solid #f7f7f7;
      }
    }
    &:first-of-type {
      font-weight: 600;
    }
    th {
      padding: 5px 10px;
    }
    td {
      padding: 5px 10px;
      ${theme.below.md} {
        font-size: 11px;
      }
    }
    td:first-of-type {
      width: 20%;
      ${theme.below.md} {
        width: 40%;
      }
    }
  }
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 30px;
  a,
  li {
    font-size: 11px;
    color: ${theme.colors.primary};
    text-decoration: none;
  }
  a {
    font-weight: normal;
  }
  li {
    font-weight: bold;
  }
`;

const HeaderImage = styled(Image)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

export const Inner = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  .content-header {
    margin-bottom: 2rem;
  }
`;

const ReadMoreToggler = styled('button')`
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-decoration-line: underline;
  appearance: none;
  border-radius: 0;
  background: none;
  text-align: center;
  font-weight: bold;
  margin-top: 0.7rem;

  &:focus {
    outline: none;
  }
`;

const StyledImage = styled('div')`
  display: flex;
  align-content: center;
  justify-content: center;
  height: 150px;
  margin: auto;
  width: 200px;
`;
const ShortcodesWrapper = styled('div')`
  width: 100%;
  height: auto;
`;

export const cleanTextString = (text) => {
  // Remove leading '-'
  const textWithoutDash = text.replace(/^-\s*/, '').replace(/(\s)-([A-Za-z])/, '$1$2');;

  // Remove '*auto*'
  const finalText = textWithoutDash.replace(/\s*\*auto\*\s*$/, '');

  return finalText;
}

export const cleanBreadcrumbs = (breadcrumbs) => {

  // Remove '*auto*'
  const finalText = cleanTextString(breadcrumbs.breadcrumbText)

 
  return { ...breadcrumbs, breadcrumbText: finalText };
}

const Content = ({ category, breadcrumbProps, active }) => {
  const excludeFlowboxCategories = [540, 1654, 626, 744];
  const flowboxTags = [];
  flowboxTags.push(category?.name);
  if (category?.parent) {
    flowboxTags.push(category?.parent?.name);
    if (category?.parent?.parent) {
      flowboxTags.push(category?.parent?.parent?.name);
    }
  }
  const items = category?.data?.items;

  return (
    <Inner>
      <StyledBreadcrumbs {...cleanBreadcrumbs(breadcrumbProps)} />

      {items?.length > 0 ? (
        <div className='content-header'>
          <CategoryName className='with-content' data-testid="page-header">
            {category.mainHeader}
          </CategoryName>
          <ContentRenderer
            items={items}
            components={allComponents}
          />
        </div>
      ) : (
        <>
          <CategoryName data-testid="page-header">
            {category.mainHeader}
          </CategoryName>
          {category.parent &&
            category.parent.id &&
            category.parent.id === 540 && (
              <StyledImage>
                <Image
                  src={category.images[4].url}
                  aspect="2:1"
                  alt={category.name}
                />
              </StyledImage>
            )}
        </>
        // !excludeFlowboxCategories.includes(category.id) && (
        //   <Flowbox tags={flowboxTags} feed="rgpHbtnSRAGHahy_TlmHFQ" />
        // )
      )}

      {category.content.length > 0 && (
        <CategoryContent
          data-testid="category-description"
          className={active ? 'active' : null}
          dangerouslySetInnerHTML={{
            __html: category.content
          }}
        />
      )}
    </Inner>
  );
};

const SizeChart = ({ category, breadcrumbProps }) => (
  <Inner>
    <NoImageWrapper>
      <StyledBreadcrumbs {...breadcrumbProps} />
      <CategoryName data-testid="page-header">{category.name}</CategoryName>

      {category.content.length > 0 && (
        <SizeChartContent
          data-testid="category-description"
          dangerouslySetInnerHTML={{
            __html: category.content
          }}
        />
      )}
    </NoImageWrapper>
  </Inner>
);

const CategoryHeader = ({ category, parents }) => {
  const [active, setActive] = useState(false);

  if (!category) return null;
  const breadcrumbProps = {
    breadcrumbText: category?.breadcrumbText,
    parents
  };
  const isImageBackground = category?.images && category?.images?.length > 0;
  const isVimeoVideo = category?.content?.includes('vimeo.com/');

  if (category?.id === 2262) {
    return <Timeline content={category.content} {...category} />;
  }
  

  if (isVimeoVideo) {
    return (
      <CategoryHeaderWrapper className={isVimeoVideo ? 'vimeo' : ''}>
        <ShortcodesWrapper>
          <Shortcodes
            content={category?.content}
            components={{
              VideoHero: VideoHero
            }}
          />
        </ShortcodesWrapper>
      </CategoryHeaderWrapper>
    );
  }

  return (
    <CategoryHeaderWrapper>
      {category.parent && category.parent.id && category.parent.id === 191 ? (
        <SizeChart category={category} breadcrumbProps={breadcrumbProps} />
      ) : (
        <>
          {isImageBackground && category.parent.id != 540 ? (
            <CategoryImageWrapper>
              <HeaderImage
                src={category.images}
                fillAvailableSpace
                cover
                quality={80}
              >
                <Content
                  active={active ? true : false}
                  category={category}
                  breadcrumbProps={breadcrumbProps}
                />
                <ReadMoreToggler onClick={() => setActive(!active)}>
                  {active ? t('Read less') : t('Read more')}
                </ReadMoreToggler>
              </HeaderImage>
            </CategoryImageWrapper>
          ) : (
            <NoImageWrapper>
              <Above breakpoint="lg">
                {matches =>
                  matches ? (
                    <>
                      <Content
                        active={
                          active || category.content.length < 500 ? true : false
                        }
                        category={category}
                        breadcrumbProps={breadcrumbProps}
                      />
                      {category.content.length > 500 ? (
                        <ReadMoreToggler onClick={() => setActive(!active)}>
                          {active ? t('Read less') : t('Read more')}
                        </ReadMoreToggler>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <Content
                        active={
                          active || category.content.length < 100 ? true : false
                        }
                        category={category}
                        breadcrumbProps={breadcrumbProps}
                      />
                      {category.content.length > 100 ? (
                        <ReadMoreToggler onClick={() => setActive(!active)}>
                          {active ? t('Read less') : t('Read more')}
                        </ReadMoreToggler>
                      ) : null}
                    </>
                  )
                }
              </Above>
            </NoImageWrapper>
          )}
        </>
      )}
    </CategoryHeaderWrapper>
  );
};

export default CategoryHeader;
